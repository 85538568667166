<template>
  <div class="container wrapper wrapper_space-top">
    <div class="wrapper">
      <div class="d-flex justify-space-between">
        <h1 class="pageHeader">
          {{ $t('label.notification') }}
        </h1>
      </div>
      <!-- <v-container v-if="itemsObj" class="" fluid> -->
      <div class="d-flex align-center pb-5 text-capitalize">
        <span class="pr-3 cursor-pointer" @click="clickSelect = !clickSelect"
          >Select</span
        >
        <span class="pr-3">|</span>
        <span
          class="pr-3 cursor-pointer"
          @click="selectAllInbox = !selectAllInbox"
        >
          {{ $t(`label.selectAll`) }}</span
        >
        <span class="pr-3">|</span>
        <span class="pr-3 cursor-pointer" @click="deleteInbox">
          {{ $t(`label.delete`) }}</span
        >
      </div>
      <template v-for="(item, i) in itemsObj">
        <div
          :key="i"
          :style="'animation-delay:' + item.no + 'ms;'"
          class="listBg inbox-checkbox mb-5 animation"
        >
          <v-col cols="12" class="d-flex align-center">
            <v-checkbox
              v-if="clickSelect"
              on-icon="mdi-check-circle-outline"
              off-icon="mdi-checkbox-blank-circle-outline"
              class="pl-2"
              :value="item.id"
              v-model="selectedInboxes"
              multiple
              number
              dark
              color="primary"
            ></v-checkbox>

            <v-list-group
              color="white"
              :value="false"
              class="w-100"
              @click="getInboxDetails(item)"
            >
              <!-- {{ item.castingJob }} -->
              <template v-slot:activator>
                <v-list-item-content class="">
                  <v-list-item-title
                    class="pink2--text py-2"
                    :class="item.isRead ? '' : 'font-weight-bold'"
                  >
                    <v-icon
                      v-if="!item.isRead"
                      color="primary"
                      size="15"
                      class="mr-2"
                    >
                      mdi-checkbox-blank-circle
                    </v-icon>
                    {{ item.title }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="white--text">
                    <div class="py-2">
                      {{ item.description }}
                    </div>
                    <div class="py-2">{{ item.createdDatetime }}</div>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </template>
              <v-list-item class="px-4">
                <v-list-item-content class="px-2 pt-5 white--text">
                  <template
                    v-if="item.castingJob && item.notificationType != 24"
                  >
                    <img
                      class="py-10 newsfeedImage cursor-pointer"
                      style="margin: auto"
                      v-if="item.castingJob.jobCoverFile"
                      :src="item.castingJob.jobCoverFile.media_path"
                      crossorigin="anonymous"
                      @click="showImage = item.castingJob.jobCoverFile.media_path; showImageModal = true"
                    />
                    <v-list-item-title class="py-1 pt-5 cursor-pointer">
                      <router-link
                        class="text-decoration-none white--text d-flex"
                        :to="{
                          name: routeName.JOB_DETAILS,
                          slug: item.castingJob.id,
                          params: {
                            id: item.castingJob.id
                          }
                        }"
                      >
                        <div class="pr-12">
                          {{ item.castingJob.title }}
                          <div class="pt-1 aqua-blue--text font-weight-light sm-font">
                            {{ item.castingJob.projectType }}
                          </div>
                          <div
                            class="py-1 grey--text font-weight-light sm-font"
                            v-if="
                              item.notificationType == 11 ||
                              item.notificationType == 30
                            "
                          >
                            {{ item.castingJob.castingRoles.character }}
                            <span
                              >(Age can play
                              {{
                                item.castingJob.castingRoles.age_range
                              }})</span
                            >
                          </div>
                        </div>
                        <div class="align-self-center ml-6">
                          <v-icon class="icon" color="white">
                            mdi-chevron-right
                          </v-icon>
                        </div>
                      </router-link>
                    </v-list-item-title>
                    <!-- <v-list-item-subtitle class="white--text">
                      <div v-if="item.castingJob.castingRoles" class="py-1">
                        <span v-if="item.castingJob.castingRoles.character"
                          >{{ item.castingJob.castingRoles.character }} </span
                        >(On-screen Age
                        <span v-if="item.castingJob.castingRoles.gender"
                          >{{ item.castingJob.castingRoles.gender }}
                        </span>
                        <span v-if="item.castingJob.castingRoles.age_range">{{
                          item.castingJob.castingRoles.age_range
                        }}</span
                        >)
                        <span></span>
                      </div>
                    </v-list-item-subtitle> -->
                  </template>
                  <template v-if="item.newsfeed">
                    <template v-if="item.newsfeed.media">
                      <img
                        class="newsfeedImage mb-5"
                        v-if="item.newsfeed.media.mime_type.includes('image')"
                        :src="item.newsfeed.media.media_path"
                        crossorigin="anonymous"
                        @click="showImage = item.newsfeed.media.media_path; showImageModal = true"
                      />
                      <video
                        controls
                        v-if="item.newsfeed.media.mime_type.includes('video')"
                        class="newsfeedImage mb-5"
                        crossorigin="anonymous"
                      >
                        <source
                          :src="item.newsfeed.media.media_path"
                          :type="item.newsfeed.media.mime_type"
                        />
                        Your browser does not support the video tag.
                      </video>
                    </template>
                    <v-list-item-title class="mb-5">
                      {{ item.newsfeed.content }}
                    </v-list-item-title>
                  </template>
                  <template
                    v-if="
                      item.castingInvitation &&
                      item.castingInvitation.status != 'pending'
                    "
                  >
                    <div>
                      {{ `${$t('label.status')}: ` }}
                      <span
                        class="font-weight-bold text-capitalize ml-2"
                        :class="
                          item.castingInvitation.status == 'accepted'
                            ? 'aqua-blue--text'
                            : 'pink2--text'
                        "
                      >
                        {{ item.castingInvitation.status }}
                      </span>
                    </div>
                  </template>
                  <!-- {{ item.notificationType }} -->

                  <v-row justify="center" class="">
                    <router-link
                      v-if="item.notificationType == 2"
                      class="text-decoration-none my-10"
                      :to="{
                        name: routeName.JOB_ORGANIZER,
                        params: {
                          reviewId: item.castingJob.id
                        }
                      }"
                    >
                      <button class="btn1">Write Review</button>
                    </router-link>
                    <router-link
                      v-else-if="item.notificationType == 9"
                      class="text-decoration-none my-10"
                      :to="{
                        name: routeName.PROFILE,
                        params: {
                          showVerifyModal: true
                        }
                      }"
                    >
                      <button class="btn1">Reverify</button>
                    </router-link>
                    <router-link
                      v-else-if="
                        [13, 27, 33, 32, 36, 37].includes(item.notificationType)
                      "
                      class="text-decoration-none my-10"
                      :to="{
                        name: routeName[
                          `${
                            role == 'talent'
                              ? 'LINKED_AGENCY'
                              : 'TALENT_ACCOUNT'
                          }`
                        ],
                        params: {
                          tab: [33, 32, 36, 37].includes(item.notificationType) ? null : 'pendings'
                        }
                      }"
                    >
                      <button class="btn1" v-if="item.notificationType != 36">View List</button>
                      <button class="btn1" v-else>View Talents</button>
                    </router-link>
                    <router-link
                      v-else-if="
                        [4, 3].includes(item.notificationType)
                      "
                      class="text-decoration-none my-10"
                      :to="{
                        name: routeName.APPLICATION_MANAGEMENT,
                        query: {
                          job_id: item.castingJob.id
                        }
                      }"
                    >
                      <button
                        class="btn1"
                        v-text="$t('label.viewApplication')"
                      ></button>
                    </router-link>
                    <!-- temp -->
                    <router-link
                      v-else-if="
                        [31, 34, 35].includes(item.notificationType)
                      "
                      class="text-decoration-none my-10"
                      :to="{
                        name: routeName.APPLICATION_MANAGEMENT,
                        query: {
                          job_id: item.modelId
                        }
                      }"
                    >
                      <button
                        class="btn1"
                        v-text="$t('label.viewApplication')"
                      ></button>
                    </router-link>
                    <router-link
                      v-else-if="item.notificationType == 1"
                      class="text-decoration-none my-10"
                      :to="{
                        name: routeName.APPLICATION_MANAGEMENT,
                        query: {
                          job_id: item.castingJob.id
                        }
                      }"
                    >
                      <button class="btn1">Upload Video</button>
                    </router-link>
                    <div
                      v-else-if="
                        item.notificationType == 11 &&
                        item.castingInvitation.status == 'pending'
                      "
                      class="text-decoration-none my-10"
                    >
                      <button
                        class="btn1 mb-6"
                        @click="
                          respondToInvitation(
                            item.castingInvitation.id,
                            'accepted',
                            item.id
                          )
                        "
                      >
                        {{ $t('label.imInterested') }}
                      </button>
                      <div class="text-center">
                        <a
                          class="underlineBtn white--text"
                          @click="
                            respondToInvitation(
                              item.castingInvitation.id,
                              'declined',
                              item.id
                            )
                          "
                        >
                          Drop Invitation
                        </a>
                      </div>
                    </div>
                    <router-link
                      v-else-if="
                        item.notificationType == 11 &&
                        item.castingInvitation.status != 'pending'
                      "
                      class="text-decoration-none my-10"
                      :to="{
                        name: routeName.JOB_DETAILS,
                        slug: item.castingJob.id,
                        params: {
                          id: item.castingJob.id
                        }
                      }"
                    >
                      <button class="btn1">See Post</button>
                    </router-link>
                    <router-link
                      v-else-if="[30, 24].includes(item.notificationType)"
                      class="text-decoration-none my-10"
                      :to="{
                        name: routeName.JOB_DETAILS,
                        slug: item.castingJob.id,
                        params: {
                          id: item.castingJob.id
                        }
                      }"
                    >
                      <button class="btn1">See Post</button>
                    </router-link>
                    <router-link
                      v-else-if="
                        item.notificationType == 8 ||
                        item.notificationType == 12
                      "
                      class="text-decoration-none my-10"
                      :to="{
                        name: routeName.PROFILE,
                        query: {
                          id: item.modelId
                        }
                      }"
                    >
                      <button class="btn1">View Profile</button>
                    </router-link>
                    <router-link
                      v-else-if="
                        (item.notificationType == 19 ||
                          item.notificationType == 18) &&
                        item.newsfeed
                      "
                      class="text-decoration-none my-10"
                      :to="{
                        name: routeName.HOME,
                        params: {
                          type: 'my',
                          id: item.modelId
                        }
                      }"
                    >
                      <button class="btn1">See Post</button>
                    </router-link>
                    <router-link
                      v-else-if="
                        item.notificationType == 28 ||
                        item.notificationType == 29
                      "
                      class="text-decoration-none my-10"
                      :to="{
                        name: routeName.APPLICATION_MANAGEMENT,
                        query: {
                          job_id: item.castingJob.id
                        }
                      }"
                    >
                      <button class="btn1">View Application</button>
                    </router-link>
                    <button
                      v-else-if="item.notificationType == 19 && !item.newsfeed"
                      class="btn1 my-10"
                      @click="showNewsfeedDeletedModal"
                    >
                      See Post
                    </button>
                    <button
                      v-else-if="item.notificationType == 17"
                      class="btn1 my-10"
                      @click="openChatWithId(item.chatGroupId)"
                    >
                      View Message
                    </button>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </v-col>
        </div>
      </template>
      <div v-if="itemsObj.length" v-observe-visibility="scrolledToBottom"></div>
      <AppImageModal
        :show="showImageModal"
        :image="showImage"
        @close="showImageModal = false; showImage = null;"
      />
      <!-- </v-container> -->
    </div>
  </div>
</template>

<script>
  import {
    INBOX_GET_INBOXS,
    INBOX_GET_INBOX_DETAILS,
    INBOX_DELETE_INBOX,
    INBOX_INITIAL_GET_INBOXS_STATE,
    INBOX_INITIAL_GET_INBOX_DETAILS_STATE,
    INBOX_INITIAL_DELETE_INBOX_STATE
  } from '@/store/inbox.module';
  import { HOME_SCREENS_GET_UNREAD_NOTIFICATIONS } from '@/store/home-screen.module';
  import {
    JOB_ORGANIZER_UPDATE_INVITATION_STATUS,
    JOB_ORGANIZER_INITIAL_UPDATE_INVITATION_STATUS_STATE
  } from '@/store/job-organizer.module';
  import { ROUTE_NAME } from '@/constants';

  export default {
    name: 'Notification',
    components: '',
    data: () => ({
      itemsObj: [],
      itemDetail: [],
      selectedInboxes: [],
      selectAllInbox: false,
      clickSelect: false,
      perPage: 10,
      routeName: ROUTE_NAME,
      showImageModal: false,
      showImage: null
    }),
    mounted() {
      this.getInboxs();
    },
    computed: {
      role() {
        return this.$store.state.auth.me.data.role;
      },
      inboxsComplete() {
        return this.$store.state.inbox.inboxsList.complete;
      },
      inboxDetailsComplete() {
        return this.$store.state.inbox.inboxDetails.complete;
      },
      deleteInboxComplete() {
        return this.$store.state.inbox.deleteInbox.complete;
      },
      updateInvitationStatusComplete() {
        return this.$store.state.jobOrganizer.updateInvitationStatus.complete;
      }
    },
    watch: {
      selectAllInbox(val) {
        if (val) {
          this.clickSelect = true;
          this.selectedInboxes = this.itemsObj.map((x) => x.id);
        } else this.selectedInboxes = [];
      },
      inboxsComplete() {
        let response = this.$store.state.inbox.inboxsList;
        if (response.complete) {
          this.importInboxsObj(response);
        }
      },
      inboxDetailsComplete() {
        let response = this.$store.state.inbox.inboxDetails;
        if (response.complete) {
          this.importInboxDetailsObj(response);
        }
      },
      deleteInboxComplete() {
        let response = this.$store.state.inbox.deleteInbox;
        if (response.complete) {
          this.selectedInboxes = [];
          this.initialDeleteInboxState();
          this.getInboxs();
        }
      },
      updateInvitationStatusComplete() {
        let response = this.$store.state.jobOrganizer.updateInvitationStatus;
        if (response.complete) {
          this.updateInvitationCompleteAction(response);
        }
      }
    },
    methods: {
      importInboxsObj(response) {
        this.itemsObj = response.data;
        if (this.selectAllInbox) {
          this.selectedInboxes = this.itemsObj.map((x) => x.id);
        }
        if (this.itemsObj) {
          this.inboxsCompleteAction();
        }
      },
      importInboxDetailsObj(response) {
        // this.itemDetail = response.data;
        if (response.code == 200) {
          this.$nextTick(() => {
            let findItem = this.itemsObj.find((x) => x.id == response.data.id);
            findItem.isRead = true;
            this.initialGetInboxDetailsState();
            this.$store.dispatch(HOME_SCREENS_GET_UNREAD_NOTIFICATIONS);
          });
        }
      },
      inboxsCompleteAction() {
        this.initialGetInboxsState();
      },
      getInboxs() {
        let data = {
          perPage: this.perPage
        };
        this.$store.dispatch(INBOX_GET_INBOXS, { data });
      },
      initialGetInboxsState() {
        this.$store.dispatch(INBOX_INITIAL_GET_INBOXS_STATE);
      },
      getInboxDetails(item) {
        if (item.isRead) return;
        this.$store.dispatch(INBOX_GET_INBOX_DETAILS, { id: item.id });
      },
      async initialGetInboxDetailsState() {
        await this.$store.dispatch(INBOX_INITIAL_GET_INBOX_DETAILS_STATE);
      },
      deleteInbox() {
        let id = this.selectedInboxes;
        this.$store.dispatch(INBOX_DELETE_INBOX, { id });
      },
      initialDeleteInboxState() {
        this.$store.dispatch(INBOX_INITIAL_DELETE_INBOX_STATE);
      },
      respondToInvitation(id, status, notificationId) {
        this.$store.dispatch(JOB_ORGANIZER_UPDATE_INVITATION_STATUS, {
          id,
          data: { status },
          notificationId
        });
      },
      updateInvitationCompleteAction(response) {
        if (response.code == 200) {
          const item = this.itemsObj.find(
            (x) => x.id == response.data.notificationId
          );
          this.openAppDialogInfo(
            'success',
            'Invitation updated successfully',
            '',
            [
              {
                text: 'OK',
                action: () => {
                  this.closeAppDialogInfo();
                  item.castingInvitation.status = response.data.status;
                }
              }
            ]
          );
        } else {
          this.openAppDialogInfo(
            'error',
            response.message || 'Request Failed',
            '',
            [
              {
                text: 'OK',
                action: () => {
                  this.closeAppDialogInfo();
                }
              }
            ]
          );
        }
        this.$store.dispatch(
          JOB_ORGANIZER_INITIAL_UPDATE_INVITATION_STATUS_STATE
        );
      },
      showNewsfeedDeletedModal() {
        this.$openModal('messageModal', 'Newsfeed deleted.');
      },
      openChatWithId(id) {
        if(!id) {
          this.openAppDialogInfo(
            'error',
            'This chat group has been removed/expired',
            '',
            [
              {
                text: 'OK',
                action: () => {
                  this.closeAppDialogInfo();
                }
              }
            ]
          );
        } else {
          this.$openChat(id);
        }
        
      },
      async scrolledToBottom(isVisible) {
        if (!isVisible) {
          return;
        }
        this.perPage += 10;
        this.getInboxs();
      }
    }
  };
</script>

<style lang="scss" scoped>
  .newsfeedImage {
    max-width: 200px;
  }
  .btn1 {
    padding: 15px 35px !important;
  }
  .inbox-checkbox {
    .v-icon.v-icon {
      color: rgb(255, 255, 255, 0.5);
    }
  }

  ::v-deep .v-list-group .v-list-group__header .v-list-item__icon.v-list-group__header__append-icon > i.v-icon {
    color: white;
  }
  .animation {
    animation: .5s linear show forwards;
    position: relative;
    opacity: 0;
    left: -100px;
  }
  @keyframes show {
    to {
      opacity: 1;
      left: 0px;
    }
  }
</style>
